.video-slider-container {
    position: relative;
    max-width: 100%;
    margin: auto;
    padding: 40px 0;
    background: linear-gradient(45deg, #333, #666);
    border-radius: 15px;
    overflow: hidden;
  }
  
  .video-slide {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  
  .video-slide video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .banner-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .banner-overlay h2 {
    color: #fff;
    margin: 0;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
  
  .arrow.next {
    right: 10px;
  }
  
  .arrow.prev {
    left: 10px;
  }
  
  .arrow i {
    color: #fff;
    font-size: 20px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #fff;
  }
  
  .slick-dots li.slick-active button:before {
    color: orange;
  }
  