.blog-post-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post-form form {
    display: flex;
    flex-direction: column;
  }
  
  .blog-post-form .form-group {
    margin-bottom: 20px;
  }
  
  .blog-post-form label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .blog-post-form input[type="text"],
  .blog-post-form textarea,
  .blog-post-form .file-input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    box-sizing: border-box; /* Ensures padding does not increase width */
  }
  
  .blog-post-form input[type="text"]:focus,
  .blog-post-form textarea:focus,
  .blog-post-form .file-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .blog-post-form textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  .blog-post-form button {
    padding: 12px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .blog-post-form button:hover {
    background-color: #0056b3;
  }
  
  .blog-post-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Custom file input style */
  .blog-post-form .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 10px;
  }
  
  .blog-post-form .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .blog-post-form .file-input-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .blog-post-form .file-input-label:hover {
    background-color: #0056b3;
  }
  