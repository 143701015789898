.hero-slider {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
}

.slide.active {
  left: 0;
}

.overlay {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  width: 80%;
}

.overlay h2 {
  font-size: 2rem;
  margin: 0;
}

.overlay p {
  font-size: 1rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hero-slider {
    height: 250px; /* Adjust height for mobile devices */
  }

  .overlay h2 {
    font-size: 1.5rem;
  }

  .overlay p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .hero-slider {
    height: 100px; /* Further adjust height for smaller screens */
  }

  .overlay h2 {
    font-size: 1.25rem;
  }

  .overlay p {
    font-size: 0.75rem;
  }
}
