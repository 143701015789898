/* General Styles */
.contact-section {
    padding: 20px;
    background-color: #f5f7fa;
    color: #333;
    display: flex;
    justify-content: center;
  }
  
  .contact-container {
    max-width: 800px; /* Set a max-width for larger screens */
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  h2 {
    color: #007bff;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .contact-container {
      padding: 15px;
      box-shadow: none;
      border-radius: 0;
    }
  
    h2 {
      font-size: 1.5rem; /* Smaller font size for smaller screens */
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 14px;
    }
  
    .submit-button {
      font-size: 16px;
      padding: 12px;
    }
  }
  
  /* Additional styles for better aesthetics */
  @media (min-width: 1200px) {
    .contact-section {
      padding: 40px;
    }
  
    .contact-container {
      padding: 30px;
      border-radius: 12px;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    }
  
    h2 {
      font-size: 2.5rem;
    }
  }
  .popup {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    z-index: 1000;
}

.popup.success {
    background-color: #1f11dd; /* Green */
}

.popup.error {
    background-color: #6936f4; /* Red */
}
  