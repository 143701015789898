.event-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .event-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .event-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .event-date {
    font-size: 1.25rem;
    color: #666;
  }
  
  .event-description,
  .event-schedule,
  .event-gallery {
    margin-bottom: 40px;
  }
  
  .event-description h2,
  .event-schedule h2,
  .event-gallery h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #444;
  }
  
  .event-description p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
  }
  
  .event-schedule .schedule-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .schedule-time {
    font-weight: bold;
    color: #2f00ff;
  }
  
  .schedule-activity {
    color: #333;
  }
  
  .event-gallery .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
  
  .event-gallery .gallery-grid img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .event-gallery .gallery-grid img:hover {
    transform: scale(1.05);
  }
  
  .event-footer {
    text-align: center;
  }
  
  .register-button {
    padding: 15px 30px;
    font-size: 1.25rem;
    color: #fff;
    background-color: #2f00ff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #2f00ff;
  }
  