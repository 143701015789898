/* Default styles for desktop */
.navbar-area .mobile-nav {
    display: none;
  }
  
  .navbar-area .main-nav {
    display: block;
  }
  
  /* Styles for mobile */
  @media (max-width: 768px) {
    .navbar-area .mobile-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px;
      background-color: #f8f8f8;
    }
  
    .navbar-area .mobile-nav-toggle {
      background: none;
      border: none;
      font-size: 1.5rem;
    }
  
    .navbar-area .mobile-nav-menu {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
  
    .navbar-area .mobile-nav-menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    .navbar-area .mobile-nav-menu ul li {
      padding: 5px 10px;
      border-bottom: 1px solid #ddd;
    }
  
    .navbar-area .mobile-nav-menu ul li a {
      text-decoration: none;
      color: #333;
      display: block;
    }
  
    .navbar-area .main-nav {
      display: none;
    }
  }
  