/* Container for the slider */
.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  /* Wrapper for the slides */
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  /* Individual slide */
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the image */
  .slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Style for the text content */
  .slide-text {
    max-width: 50%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    text-align: center;
  }
  
  .slide-text h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .slide-text p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  /* Navigation buttons */
  .prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .slide-text {
      max-width: 80%;
    }
  }
  