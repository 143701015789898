/* Mobile Header Styles */
.mob-header {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure relative positioning for absolute elements */
  }
  
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .mobile-nav .logo img {
    height: 40px;
  }
  
  .mobile-nav-toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute; /* Position the button absolutely */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    content: "";
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba%280, 0, 0, 0.5%29" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
  }
  
  /* Mobile Navigation Menu */
  .mobile-nav-menu {
    background-color: #fff;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .mobile-nav-menu ul li {
    border-bottom: 1px solid #ddd;
  }
  
  .mobile-nav-menu ul li a {
    display: block;
    padding: 15px 20px;
    text-decoration: none;
    color: #333;
  }
  
  .mobile-nav-menu ul li a:hover {
    background-color: #f5f5f5;
  }
  
  /* Hide desktop nav on mobile */
  @media (max-width: 768px) {
    .main-nav {
      display: none;
    }
  }
  