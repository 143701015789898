/* headslider.css */

.section-padding {
    padding: 60px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .section-title h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.8;
  }
  
  .theme-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff6600;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .theme-btn:hover {
    background-color: #e55d00;
  }
  
  .all-service-link {
    text-align: right;
  }
  
  .services-grids {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
  }
  
  .grid {
    flex: 1 1 calc(25% - 20px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .grid:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .inner {
    position: relative;
    overflow: hidden;
  }
  
  .inner .bg-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .inner:hover .bg-image {
    transform: scale(1.1);
  }
  
  .details {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .details h3 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
  }
  
  .details p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .details .more {
    display: inline-block;
    padding: 8px 15px;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .details .more:hover {
    background-color: #000;
  }
  
  @media (max-width: 992px) {
    .grid {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .grid {
      flex: 1 1 100%;
    }
  
    .all-service-link {
      text-align: left;
      margin-top: 20px;
    }
  }
  