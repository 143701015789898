/* Blogdetails.css */

.banner-area {
    background: #f8f9fa;
    padding: 40px 0;
    border-bottom: 1px solid #e9ecef;
}

.inner-banner {
 
    padding: 50px 0;
    color: #fff;
}

.inner-title h3 {
    font-size: 2.5em;
    margin-bottom: 15px;
}

.inner-title ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.inner-title ul li {
    margin: 0 10px;
}

.inner-title ul li a {
    color: #fff;
    text-decoration: none;
}

.inner-title ul li a:hover {
    text-decoration: underline;
}

.blog-dtls {
    background-color: #fff;
    padding: 50px 0;
    border-top: 1px solid #e9ecef;
}

.blog-dtls-content {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-dtls-img img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.blog-text {
    text-align: left;
    line-height: 1.8;
    color: #333;
}

.blog-text h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.blog-text p {
    font-size: 1.1em;
    margin-bottom: 20px;
}

.article-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.article-tags {
    display: flex;
    align-items: center;
}

.article-tags span {
    margin-right: 10px;
    font-size: 1.2em;
}

.article-share ul {
    display: flex;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.article-share ul li a {
    font-size: 1.5em;
    color: #007bff;
    transition: color 0.3s;
}

.article-share ul li a:hover {
    color: #0056b3;
}

.post-navigation {
    margin-top: 30px;
}

.navigation-links {
    display: flex;
    justify-content: space-between;
}

.nav-previous a,
.nav-next a {
    font-size: 1.1em;
    color: #007bff;
    text-decoration: none;
}

.nav-previous a:hover,
.nav-next a:hover {
    text-decoration: underline;
}

.error-message {
    color: #f44336;
    font-size: 1.2em;
    text-align: center;
    margin-top: 20px;
}

.loading {
    font-size: 1.2em;
    text-align: center;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .inner-title h3 {
        font-size: 2em;
    }

    .blog-text h2 {
        font-size: 2em;
    }

    .blog-text p {
        font-size: 1em;
    }

    .article-share ul {
        flex-direction: column;
    }

    .article-share ul li {
        margin-bottom: 10px;
    }
}
